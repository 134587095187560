/* layout et backgrounds grands blocs */

html {
  background-color: $body-bg;
}

body {
  background: #fff url(img/header-auberge.jpg) top no-repeat;
  background-size: contain;
}

#page {
  max-width: 80em;
  margin: 0 auto;
  padding: 0;
  background-color: rgba(255,255,255,.7);

  @media screen and (min-width: $small-screen) {
    padding: 0 .5em;
  }
}

#wrapper {
  width: 100%;
  overflow: hidden;
  margin: 0 auto;
  padding: 1rem .25rem;

  @media screen and (min-width: $x-large-screen) {
    padding: 1.5rem .5rem;
  }
}

.header {
  @media screen and (max-width: 24rem) {
    padding-bottom: 9vw;
  }
}

.banner {
  padding: .825em .5rem .125rem;

  @media screen and(min-width: $medium-screen) {
    padding: .825em 2rem .125rem;
  }
}


// special accueil
.dc-home-first {
  #page {
    background: transparent;
  }

  .header {
    height: 41.5vw;
  }

  .banner {
    background: linear-gradient(to right, rgba(255,255,255,.75), rgba(255,255,255,.1));
  }
}
