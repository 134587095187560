@charset "UTF-8";
/** -------------------------------------------------- Start --------------------------------------------------- */
/* Génère une liste de couleurs utilisées pour un gradient de 0 à 100% chaque couleur est répétée deux fois pour obtenir des aplats. Si $ignore est fourni, il contient une liste de clés à ignorer. Utilisation: 1. Créer une map de couleurs $flag_color ou donner à $flag_color une map existante ou indiquer la map à utiliser en premier paramètre de la fonction 2. La fonction crée les valeurs à renseigner pour la propriété gradient-color. Exemple: background: linear-gradient(90deg, prettyflag($room_color, ('notes','room_14'))); */
html, body { box-sizing: border-box; margin: 0; padding: 0; }

*, *::before, *::after { box-sizing: inherit; }

@-moz-viewport { width: device-width; }

@-ms-viewport { width: device-width; }

@-o-viewport { width: device-width; }

@-webkit-viewport { width: device-width; }

@viewport { width: device-width; }

article, aside, details, figcaption, figure, footer, header, hgroup, main, menu, nav, section, summary { display: block; }

audio, canvas, progress, video { display: inline-block; vertical-align: baseline; }

h1, h2, h3, h4, h5 { margin: 0; font-weight: normal; }

audio:not([controls]) { display: none; height: 0; }

[hidden] { display: none; }

a { background-color: transparent; }

abbr { text-decoration: none; border-bottom: 0; }

abbr[title] { border-bottom: 1px dotted; }

b, strong { font-weight: bold; }

dfn { font-style: italic; }

small { font-size: 80%; }

sub, sup { font-size: 75%; line-height: 0; position: relative; vertical-align: baseline; }

sup { top: -0.5em; }

sub { bottom: -0.25em; }

img { border: 0; max-width: 100%; height: auto; }

svg:not(:root) { overflow: hidden; }

figure { margin: 0; }

hr { box-sizing: content-box; height: 0; }

pre { overflow: auto; }

code, kbd, pre, samp { font-family: monospace; font-size: 1em; }

button, input, optgroup, select, textarea { color: inherit; font: inherit; margin: 0; }

button { overflow: visible; user-select: auto; }

button, select { text-transform: none; }

button, html input[type="button"], input[type="reset"], input[type="submit"] { cursor: pointer; }

button[disabled], html input[disabled] { cursor: default; }

button::-moz-focus-inner, input::-moz-focus-inner { border: 0; padding: 0; }

input { line-height: normal; }

input[type="checkbox"], input[type="radio"] { box-sizing: border-box; padding: 0; }

input[type="number"]::-webkit-inner-spin-button, input[type="number"]::-webkit-outer-spin-button { height: auto; }

input[type="search"] { -webkit-appearance: textfield; box-sizing: content-box; }

input[type="search"]::-webkit-search-cancel-button, input[type="search"]::-webkit-search-decoration { -webkit-appearance: none; }

fieldset { border: 1px solid #c0c0c0; margin: 0 2px; padding: 0.35em 0.625em 0.75em; }

legend { border: 0; padding: 0; }

textarea { overflow: auto; }

optgroup { font-weight: bold; }

table { border-collapse: collapse; border-spacing: 0; }

td, th { padding: 0; }

[tabindex="-1"]:focus { outline: none !important; }

[role="button"] { cursor: pointer; }

a, area, button, [role="button"], input, label, select, summary, textarea { touch-action: manipulation; }

/** -------------------------------------------------- Common rules and layout --------------------------------------------------- */
html { color: #000; font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"; font-size: 100%; line-height: 1.5; }

body { font-size: 1rem; }

@media screen and (min-width: 60em) { body { font-size: 1.125rem; } }

.visually-hidden { position: absolute; width: 1px; height: 1px; margin: -1px; padding: 0; overflow: hidden; clip: rect(1px, 1px, 1px, 1px); -webkit-clip-path: inset(50%); clip-path: inset(50%); border: 0; white-space: nowrap; }

#content-info { background: rgba(255, 255, 255, 0.5); margin-bottom: 1rem; padding-bottom: .25rem; text-align: center; }

#content-info p { margin: 0 0 .25rem; }

#content-info h2 { font-family: Georgia, "Times New Roman", Times, serif; font-style: italic; font-size: 1.75rem; }

.chapter-separator { overflow: hidden; margin-bottom: 1em; text-align: center; }

.chapter-title { overflow: hidden; font-weight: normal; }

.chapter-title::before, .chapter-title::after { position: relative; display: inline-block; width: 50%; height: 1px; background-color: #000; vertical-align: middle; content: ""; }

.chapter-title:before { right: 1em; margin-left: -50%; }

.chapter-title::after { left: 1em; margin-right: -50%; }

.dc-home .chapter-title { margin-bottom: 2rem; font-size: 1.25rem; }

hr.couillard { display: block; clear: both; height: 0; padding: 0 0 1.5em; border: 0; font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"; text-align: center; font-size: 1rem; line-height: 1; }

hr.couillard::after { content: "\273D \273D \273D"; height: 0; letter-spacing: 1em; color: #8a6507; }

a { color: #d52125; text-decoration: none; transition: .3s; }

a:link, a:visited { border-bottom: 1px solid #a9a9b0; }

a:visited { border-bottom-style: dotted; }

a:hover, a:active { border-bottom: 1px solid #d52125; }

a:focus { color: #fff; background-color: #d52125; }

.icon { display: inline-block; width: 1em; height: 1em; margin-top: -.15em; margin-right: .5rem; vertical-align: middle; fill: currentColor; }

.icon-rss, .icon-mastodon, .icon-dotclear, .icon.icon-group { width: 1.25em; height: 1.25em; margin-right: .4rem; }

.icon-dotclear { margin-left: .125rem; fill: #1b7742; }

.icon-search { width: 1.5em; height: 1.5em; }

/* un comportement plus doux pour le retour en haut de page sans forcer les préférences de l'utilisateur source : https://moderncss.dev/pure-css-smooth-scroll-back-to-top/ */
@media screen and (prefers-reduced-motion: no-preference) { html, body { scroll-behavior: smooth; } }

#gotop { margin: 0; text-align: center; }

.buttons { font-size: 1.25rem; text-align: right; }

button, .button { display: inline-block; margin: 0 2px; padding: .25rem 1rem; color: #42434e; background-color: #f5f7f7; border: 1px solid #909090; border-radius: .25rem; box-shadow: 3px 3px 4px 0 rgba(0, 0, 0, 0.15); font-size: 1em; font-weight: normal; line-height: 1.5; text-align: center; vertical-align: middle; white-space: nowrap; transition: all 300ms ease; user-select: none; }

button:hover, .button:hover { transform: scale(1.05); border-color: currentColor; }

button:active, .button:active { transform: scale(0.97); }

button::-moz-focus-inner, .button::-moz-focus-inner { padding: 0; border: none; }

.submit { color: #fff; background-color: #aa00aa; border-color: #910091; }

.submit:focus { background-color: #000; }

.preview { margin-right: 1rem; border-color: #aa00aa; }

input, textarea { display: inline-block; padding: .5rem .5rem; border: 1px solid #909090; }

input:focus, textarea:focus { border-color: #aa00aa; box-shadow: 0 0 0 2px rgba(150, 0, 150, 0.25); }

q::before, cite::before { content: open-quote; }

q::after, cite::after { content: close-quote; }

:lang(en), article:lang(en) { quotes: '“ ' ' ”' '‘' '’'; }

:lang(fr), article:lang(fr) { quotes: '« ' ' »' '‘' '’'; }

:lang(es), article:lang(es) { quotes: '“' '”' '‘' '’'; }

:lang(de), article:lang(de) { quotes: '» ' ' «' '‘' '’'; }

:lang(en) > q:not(:lang), :lang(en) > cite:not(:lang) { quotes: '“ ' ' ”' '‘' '’'; }

:lang(fr) > q:not(:lang), :lang(fr) > cite:not(:lang) { quotes: '« ' ' »' '‘' '’'; }

:lang(es) > q:not(:lang), :lang(es) > cite:not(:lang) { quotes: '“' '”' '‘' '’'; }

:lang(de) > q:not(:lang), :lang(de) > cite:not(:lang) { quotes: '» ' ' «' '‘' '’'; }

blockquote { background-color: #e8f3db; padding: .25em 1em; }

/* layout et backgrounds grands blocs */
html { background-color: #fff; }

body { background: #fff url(img/header-auberge.jpg) top no-repeat; background-size: contain; }

#page { max-width: 80em; margin: 0 auto; padding: 0; background-color: rgba(255, 255, 255, 0.7); }

@media screen and (min-width: 30em) { #page { padding: 0 .5em; } }

#wrapper { width: 100%; overflow: hidden; margin: 0 auto; padding: 1rem .25rem; }

@media screen and (min-width: 80em) { #wrapper { padding: 1.5rem .5rem; } }

@media screen and (max-width: 24rem) { .header { padding-bottom: 9vw; } }

.banner { padding: .825em .5rem .125rem; }

@media screen and (min-width: 40em) { .banner { padding: .825em 2rem .125rem; } }

.dc-home-first #page { background: transparent; }

.dc-home-first .header { height: 41.5vw; }

.dc-home-first .banner { background: linear-gradient(to right, rgba(255, 255, 255, 0.75), rgba(255, 255, 255, 0.1)); }

.header a:link, .header a:visited { border-bottom: 1px solid transparent; color: #000; }

.header a:hover, .header a:active, .header a:visited:hover, .header a:visited:active { border-bottom-color: #d52125; }

.header a:focus { background-color: #d52125; color: #fff; }

a.go-a11y { border-color: transparent; position: absolute; width: 1px; height: 1px; margin: -1px; padding: 0; overflow: hidden; clip: rect(1px, 1px, 1px, 1px); -webkit-clip-path: inset(50%); clip-path: inset(50%); border: 0; white-space: nowrap; }

.header a.go-a11y { display: block; padding: .125em 1.5em; background-color: #517627; border-color: white; color: #fff; }

.header a.go-a11y:active, .header a.go-a11y:focus { position: static; width: auto; height: auto; margin: 0; overflow: visible; clip: auto; -webkit-clip-path: none; clip-path: none; white-space: normal; }

.skip-links { padding-left: 0; list-style: none; margin: 0; padding-right: .5rem; font-size: .875rem; text-align: right; }

.skip-links > li { display: inline-block; }

.skip-links > li:not(:last-child) { margin-right: 0; }

.skip-links a { padding: 0 .125rem; }

@media screen and (max-width: 23em) { .skip-links { padding-right: .25em; font-size: .8rem; } }

@media screen and (max-width: 60em) { .dc-home-first .skip-links > li { background: rgba(255, 255, 255, 0.6); }
  .dc-home-first .skip-links > li:first-child { margin-right: -.25rem; } }

.site-title { margin: 0; line-height: 1.25; font-size: 1.75rem; font-family: Georgia, "Times New Roman", Times, serif; font-weight: normal; }

@media screen and (min-width: 23em) { .site-title { font-size: 2rem; } }

@media screen and (min-width: 60em) { .site-title { font-size: 3rem; } }

.site-baseline { margin-top: 0; }

@media screen and (min-width: 60em) { .site-baseline { font-size: 1.5rem; } }

.footer { overflow: hidden; margin-top: 4rem; }

.footer a:link, .footer a:visited { border-bottom: 1px solid transparent; color: #000; }

.footer a:hover, .footer a:active, .footer a:visited:hover, .footer a:visited:active { border-bottom-color: #d52125; }

.footer a:focus { background-color: #d52125; color: #fff; }

.footer ul { padding-left: 0; list-style: none; margin: 0 auto; }

.footer h2 { margin: 0 0 .5rem; font-size: 1.5rem; }

.footer h3 { margin: 1rem 0 0; font-family: Georgia, "Times New Roman", Times, serif; font-size: 1.5rem; font-weight: normal; }

.footer-top { padding: 1rem .5rem; background: #fff url(img/header-auberge.jpg) center no-repeat; background-size: cover; font-size: 1.25rem; font-weight: bold; text-align: center; }

@media screen and (min-width: 30em) { .footer-top { padding: 1rem 1rem; } }

@media screen and (min-width: 60em) { .footer-top { margin: 1rem 3rem; padding: 1rem 2rem; } }

.footer-top .container { padding: .5rem; background-color: rgba(255, 255, 255, 0.7); }

.footer-top p { margin-top: 0; }

@media screen and (min-width: 30em) { .infos-auberge ul { padding-left: 0; list-style: none; }
  .infos-auberge ul > li { display: inline-block; }
  .infos-auberge ul > li:not(:last-child) { margin-right: 2rem; } }

.footer-search { max-width: 40em; margin: 2rem .5em 0; line-height: 2; }

@media screen and (min-width: 60em) { .footer-search { margin: 3rem auto 0; } }

h2.search-title { margin-bottom: 0; font-size: 1rem; }

.search-form .input-search-group { display: flex; margin: 0; }

.search-form .input-search-group input, .search-form .input-search-group button { border: 1px solid rgba(147, 128, 108, 0.5); }

.search-form .input-search-group input[type="search"] { display: block; margin-right: -1px; padding: .5em; flex: 1; }

.search-form .input-search-group .button { display: block; width: 5em; border-radius: 0 .25em .25em 0; }

.footer-bottom { padding: 1rem .5rem; line-height: 2; }

@media screen and (min-width: 40em) { .footer-bottom { display: flex; justify-content: space-between; }
  .footer-bottom section { min-width: 13em; } }

@media screen and (min-width: 60em) { .footer-bottom { margin: 1rem 3rem; } }

.feeds { margin: 0; padding-top: .5em; border-top: 1px solid #a9a9b0; text-align: center; }

.feeds li { display: inline-block; margin: 0 1em; }

.quality { margin-bottom: 0; margin-top: 3rem; padding: .5rem; background-color: #edf6e3; border-top: 1px solid #79b039; text-align: center; font-family: Georgia, "Times New Roman", Times, serif; }

.quality a:link, .quality a:visited { border-bottom-color: #aaa; }

.footer-credits { display: flex; flex-direction: column; margin: 0; padding: .5rem; background-color: #dcedc8; overflow: hidden; }

.footer-credits a:link, .footer-credits a:visited { border-bottom-color: #aaa; }

.footer-credits > p { margin: 0; }

@media screen and (min-width: 30em) { .footer-credits { flex-direction: row; padding-bottom: 3.5em; justify-content: space-between; }
  .footer-credits .legal { text-align: right; } }

@media screen and (min-width: 60em) { .footer-credits { padding: 1rem; } }

/** -------------------------------------------------- Fragments --------------------------------------------------- */
.post { background-color: #f5f7f7; }

.post h3, .post h4 { font-family: Georgia, "Times New Roman", Times, serif; font-weight: 400; }

.simple, .post-feedback { max-width: 50em; margin: 2rem auto; }

@media screen and (min-width: 40em) { .simple, .post-feedback { margin-top: 4rem; } }

.simple { box-shadow: 4px -4px 12px rgba(0, 0, 0, 0.1); }

.simple .post-content h2 { font-size: 1.75em; font-family: Georgia, "Times New Roman", Times, serif; font-style: italic; text-align: center; margin-top: 3rem; }

.simple .post-content hr { display: block; clear: both; height: 0; padding: 0 0 1.5em; border: 0; font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"; text-align: center; font-size: 1.25rem; line-height: 1; }

.simple .post-content hr::after { content: "\273D \273D \273D"; height: 0; letter-spacing: 1em; color: #666; }

.simple .post-content pre, .simple .post-content code { font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace; font-size: .875em; }

.simple .post-content aside strong { font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace; font-weight: 500; }

.simple .footnotes { margin-top: 2.5em; padding-top: 1em; border-top: 1px dashed #666; font-size: .925rem; }

.simple .footnotes h4 { font-size: 1.125em; font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"; font-weight: 500; }

.post-full { padding: 0 .5rem 1rem; }

@media screen and (min-width: 40em) { .post-full { padding: 0 7% 2em; } }

.post-full img { box-shadow: 4px -4px 12px rgba(0, 0, 0, 0.1); padding: .75em .75em .75em .75em; background: white; }

.post-full figure img { padding-bottom: 2.5em; }

.post-full figure figcaption { margin-top: -2.75em; padding-left: 1em; font-family: Georgia, "Times New Roman", Times, serif; font-size: .8333em; font-style: italic; color: #000; }

.post-banner { display: flex; flex-wrap: wrap; }

.post-banner .post-header { width: 100%; font-size: .925rem; }

@media screen and (min-width: 42em) { .post-banner .post-header { width: auto; min-width: 17em; font-size: 1rem; } }

.post-banner .post-title { flex: 1; align-self: center; margin: 1rem 0; padding: 0 1rem; line-height: 1.25; text-align: center; font-family: Georgia, "Times New Roman", Times, serif; font-size: 2rem; font-weight: normal; font-style: italic; }

@media screen and (max-width: 42em) { .post-banner .post-title { font-size: 1.75rem; margin-top: 3rem; margin-bottom: 1rem; padding: 0 .5rem; } }

@media screen and (min-width: 42em) { .post-banner { margin-bottom: 3rem; } }

@media screen and (min-width: 60em) { .post-banner { margin-bottom: 5rem; } }

.author, .room, .date { margin: 0; }

.author { font-size: 1.5em; }

.header-info.author { line-height: 1.25em; margin-top: .125em; margin-bottom: .125em; }

.room, .date { font-size: 1em; }

.post-header { background-color: #444; color: #fff; padding: .5em 1.5em; }

.post-header.Notes-de-la-Direction { padding-left: 5em; background: #444444 url("img/avatar.jpg") no-repeat 0.75rem 50%; background-size: 3.5em auto; }

.post-header.notes { background-color: #444444; }

.post-header.staff_1 { background-color: #325ac3; }

.post-header.staff_2 { background-color: #3a539b; }

.post-header.staff_3 { background-color: #406098; }

.post-header.staff_4 { background-color: #007faa; }

.post-header.staff_5 { background-color: #16405B; }

.post-header.staff_6 { background-color: #0A3055; }

.post-header.staff_7 { background-color: #00008B; }

.post-header.staff_8 { background-color: indigo; }

.post-header.room_1 { background-color: #1e824c; }

.post-header.room_2 { background-color: #008000; }

.post-header.room_3 { background-color: #005500; }

.post-header.room_4 { background-color: #20603C; }

.post-header.room_5 { background-color: #005051; }

.post-header.room_6 { background-color: #345A5E; }

.post-header.room_7 { background-color: #9b59b6; }

.post-header.room_8 { background-color: #b500b5; }

.post-header.room_9 { background-color: #913D88; }

.post-header.room_10 { background-color: #7928a1; }

.post-header.room_11 { background-color: #8B008B; }

.post-header.room_12 { background-color: #5D3754; }

.post-header.room_13 { background-color: #a74165; }

.post-header.room_15 { background-color: #b11030; }

.post-header.room_16 { background-color: #aa2e00; }

.post-header.room_17 { background-color: #d32f2f; }

.post-header.room_18 { background-color: #c94900; }

.post-header.room_19 { background-color: #aa5535; }

.post-header.room_14 { background-color: #806c00; }

.post-header.room_20 { background-color: #802200; }

.hour.notes { border-color: #444444; }

.hour.staff_1 { border-color: #325ac3; }

.hour.staff_2 { border-color: #3a539b; }

.hour.staff_3 { border-color: #406098; }

.hour.staff_4 { border-color: #007faa; }

.hour.staff_5 { border-color: #16405B; }

.hour.staff_6 { border-color: #0A3055; }

.hour.staff_7 { border-color: #00008B; }

.hour.staff_8 { border-color: indigo; }

.hour.room_1 { border-color: #1e824c; }

.hour.room_2 { border-color: #008000; }

.hour.room_3 { border-color: #005500; }

.hour.room_4 { border-color: #20603C; }

.hour.room_5 { border-color: #005051; }

.hour.room_6 { border-color: #345A5E; }

.hour.room_7 { border-color: #9b59b6; }

.hour.room_8 { border-color: #b500b5; }

.hour.room_9 { border-color: #913D88; }

.hour.room_10 { border-color: #7928a1; }

.hour.room_11 { border-color: #8B008B; }

.hour.room_12 { border-color: #5D3754; }

.hour.room_13 { border-color: #a74165; }

.hour.room_15 { border-color: #b11030; }

.hour.room_16 { border-color: #aa2e00; }

.hour.room_17 { border-color: #d32f2f; }

.hour.room_18 { border-color: #c94900; }

.hour.room_19 { border-color: #aa5535; }

.hour.room_14 { border-color: #806c00; }

.hour.room_20 { border-color: #802200; }

.hour, .date span { font-size: .875em; padding: 2px .5em; border-radius: .25em; border: 1px solid; }

.hour { font-weight: bold; background: #fff; margin-right: .25em; }

.date span { background-color: transparent; border-color: rgba(255, 255, 255, 0.6); margin-left: .5em; }

.posts-group { width: 100%; }

@media screen and (min-width: 40em) { .posts-group { display: flex; flex-wrap: wrap; } }

.posts-group .post { position: relative; margin-bottom: 2rem; border-radius: .25rem; box-shadow: 0 10px 6px -6px rgba(0, 0, 0, 0.3); transition: box-shadow 0.2s ease-in-out; }

@media screen and (min-width: 40em) and (max-width: 80em) { .posts-group .post { flex: 0 48.5%; margin-right: 2%; overflow: hidden; }
  .posts-group .post:nth-child(2n+2) { margin-right: 0; } }

@media screen and (min-width: 80em) { .posts-group .post { flex: 0 31%; margin-bottom: 3rem; margin-right: 3.5%; overflow: hidden; }
  .posts-group .post:nth-child(3n+3) { margin-right: 0; } }

.posts-group .post:hover { box-shadow: 0 6px 10px -4px rgba(0, 0, 0, 0.5); }

.posts-group .post-header { border-radius: .25rem .25rem 0 0; }

.posts-group .post-content { padding: .25em 1em; height: 100%; }

.posts-group .post-content a:link, .posts-group .post-content a:visited { border-bottom: 1px solid transparent; color: #000; }

.posts-group .post-content a:hover, .posts-group .post-content a:active, .posts-group .post-content a:visited:hover, .posts-group .post-content a:visited:active { border-bottom-color: #d52125; }

.posts-group .post-content a:focus { background-color: #d52125; color: #fff; }

@media screen and (min-width: 30em) { .posts-group .post-content { padding: .25em 1.5em; } }

.posts-group .post-content a { font-weight: 600; }

.posts-group .post-content a::after { display: block; position: absolute; content: ""; top: 0; bottom: 0; left: 0; right: 0; }

.dc-home .Notes-de-la-Direction + .post-content, .dc-search .Notes-de-la-Direction + .post-content { background-color: #fcedc7; box-shadow: 0 0.75rem 0.75rem rgba(0, 0, 0, 0.2); }

.dc-post .Notes-de-la-Direction { background-size: 5.25em; padding-left: 6.5em; }

.dc-contactme .post { padding: 1rem; }

@media screen and (min-width: 30em) { .dc-contactme .post { padding: 1rem 2rem 2rem; } }

.post-feedback { margin-bottom: 4rem; }

.post-feedback a:link, .post-feedback a:visited { border-bottom: 1px solid transparent; color: #aa00aa; }

.post-feedback a:hover, .post-feedback a:active, .post-feedback a:visited:hover, .post-feedback a:visited:active { border-bottom-color: #d52125; }

.post-feedback a:focus { background-color: #d52125; color: #fff; }

.post-feedback a:link, .post-feedback a:visited { border-bottom-color: #aa00aa; }

.comments-list { padding-left: 0; list-style: none; }

.comment { margin-top: 2rem; margin-bottom: 2rem; padding-right: 1em; padding-left: 1em; border: 1px solid #aa00aa; border-radius: .5em; }

.comment.even { margin-left: 10%; }

.comment.odd { margin-right: 10%; }

@media screen and (min-width: 30em) { .comment.even { margin-left: 15%; }
  .comment.odd { margin-right: 15%; } }

.comment.me { background-color: #dcedc8; }

.comment-info { font-family: Georgia, "Times New Roman", Times, serif; }

.comment-number { display: inline-block; width: 3em; height: 1.5em; margin-right: .5em; text-align: center; }

.ping { position: relative; padding-right: 1em; padding-left: 1em; border: 2px solid #999; }

.ping .comment-content p:first-child { margin-bottom: 0; }

.ping .comment-content p:nth-child(2) { margin-top: 0; }

.ping strong { font-family: Georgia, "Times New Roman", Times, serif; }

#comments-feed { margin-bottom: 4rem; text-align: center; }

.comment-form { margin-bottom: 4rem; }

.field { display: flex; line-height: 2; flex-wrap: wrap; }

.field label { display: block; width: 100%; margin-bottom: .25em; padding-left: 1em; background-color: #f5f7f7; border: 1px solid #909090; white-space: nowrap; flex: none; }

@media screen and (min-width: 40em) { .field label { width: 15em; margin-right: -1px; margin-bottom: 0; } }

.field input, .field textarea { flex: 1; }

.field-content { display: block; margin-bottom: 0; margin-top: 1.5em; }

.field-content > div { margin-bottom: -1px; margin-left: 0; padding: .125rem; border: 1px solid #909090; }

.field-content label { width: 100%; margin-bottom: 1rem; }

.field-content textarea { overflow: hidden; width: 100%; line-height: 1.5; border-color: #909090; }

.field-content .jstElements { padding-left: 1rem; padding-bottom: .5rem; }

.field-content .jstElements button { border-color: transparent; }

.field-content .jstElements button:focus, .field-content .jstElements button:hover { border-color: #aa00aa; }

.field-content .jstElements.focus { padding-left: 1rem; padding-bottom: .5rem; border: 1px solid #5f892d; background-color: #dcedc8; }

.field-content .jstEditor textarea { border-color: transparent; }

.remember { margin-top: -.5rem; font-size: 1rem; }

@media screen and (min-width: 40em) { .remember { padding-left: 17em; } }

.form-help { margin-top: 0; margin-bottom: 0; font-size: 1rem; }

#ping-url { color: #666; font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace; font-size: 1rem; }

#pr { margin-bottom: 2em; padding: 1em .5em 0; border: 4px solid transparent; box-sizing: border-box; background-image: linear-gradient(white, white), linear-gradient(180deg, orange, #aa00aa 50%, orange); background-repeat: no-repeat; background-size: 100% 100%, 100% 200%; background-position: 0 0, 0 100%; background-origin: padding-box, border-box; animation: highlight .75s infinite alternate; }

#pr .buttons { margin-bottom: .5rem; }

p#pr { padding-bottom: 1em; text-align: center; }

@keyframes highlight { 100% { background-position: 0 0, 0 0; } }

/** -------------------------------------------------- Pages --------------------------------------------------- */
.content-info__cat-desc { font-size: 1.25rem; }

.category-feed { margin-bottom: 4rem; padding: 0 .5rem; }

.dc-page .post-header { display: none; }

.dc-page .post-title { margin-top: 1em; }

@media screen and (min-width: 30em) { .dc-page .post-title { margin-top: 2em; } }

.dc-page .post-excerpt { margin-bottom: 2em; padding: .5rem 1rem; background-color: #fcedc7; border: 6px double #f7ce66; border-radius: 1em; }

.dc-page .post-excerpt a { color: #a91a1d; }

@media screen and (min-width: 30em) { .dc-page .post-excerpt { padding: .5rem 2rem; } }

.dc-page .post-banner { margin-bottom: 2em; }

.dc-page h3 { font-weight: 600; }

.dc-page h4 { margin-top: 2em; padding-bottom: .5em; font-weight: 600; color: #8a6507; }

.dc-page strong { font-weight: 600; }

.dc-page .post-content ul > li { margin-bottom: .5em; }

.dc-page .chapter-separator { margin-top: 2em; }

.dc-page .post .buttons { margin-bottom: 0; }

.js-expandmore-all { background-color: white; box-shadow: none; font-size: .875rem; margin-bottom: 0; border-radius: .75em; }

.js-expandmore-all:hover, .js-expandmore-all:focus { outline: thin dotted #79b039; border-color: #446320; }

.js-expandmore { margin-bottom: 3em; }

.expandmore__button { position: relative; vertical-align: baseline; background: transparent; font-size: inherit; color: inherit; width: 100%; text-align: inherit; box-shadow: none; padding-left: 2em; margin-top: 1em; white-space: normal; user-select: text; }

.expandmore__button:hover, .expandmore__button:active { transform: none; border-color: #79b039; }

.expandmore__button[aria-expanded="true"], .expandmore__button:hover, .expandmore__button:focus { background-color: #dcedc8; border-color: #79b039; }

.expandmore__button[aria-expanded="true"]:hover { background-color: #fcedc7; border-color: #eaac0d; }

.expandmore__button:focus { outline: thin dotted #79b039; border-color: #79b039; }

.js-expandmore-panel { padding: 0 1.5em; }

.expandmore__symbol { font-size: .925em; line-height: 1; position: absolute; top: .5em; left: .5em; }

.expandmore__symbol:before { content: "➤"; }

.expandmore__button[aria-expanded=true] .expandmore__symbol:before, .expandmore__button[data-expanded=true] .expandmore__symbol:before { content: "−"; }

.no-js .js-expandmore-all { display: none; }

@media print { .js-expandmore-all { display: none; }
  .js-expandmore-panel[hidden] { display: block; } }

.dc-archive .simple { font-size: 1rem; }

@media screen and (max-width: 40em) { .dc-archive #main, .dc-tag #main { padding: 0; } }

.dc-archive .simple, .dc-tag .simple { max-width: 58em; margin-top: 1em; padding: 1em .25em; background-color: #fff; }

@media screen and (min-width: 23em) { .dc-archive .simple, .dc-tag .simple { padding-left: .5em; padding-right: .5em; } }

@media screen and (min-width: 40em) { .dc-archive .simple, .dc-tag .simple { padding-left: 1em; padding-right: 1em; } }

@media screen and (min-width: 60em) { .dc-archive .simple, .dc-tag .simple { padding-left: 2em; padding-right: 2em; } }

@media screen and (min-width: 80em) { .dc-archive .simple, .dc-tag .simple { padding: 2em; } }

.dc-archive h4, .dc-tag h4 { margin-top: 2rem; margin-bottom: 1rem; font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"; font-size: 1.125rem; font-weight: 600; text-align: center; }

.dc-archive .one-day-list, .dc-tag .one-day-list { padding-left: 0; list-style: none; margin-bottom: 4em; }

.dc-archive .arch-post, .dc-tag .arch-post { position: relative; display: flex; flex-wrap: wrap; overflow: hidden; margin-bottom: 1em; background-color: #f5f7f7; border: 1px solid #a9a9b0; }

.dc-archive .arch-post a::after, .dc-tag .arch-post a::after { position: absolute; top: 0; right: 0; bottom: 0; left: 0; content: ""; }

.dc-archive .arch-post a:hover::after, .dc-tag .arch-post a:hover::after { border: 1px solid #aa00aa; }

.dc-archive .arch-post-p, .dc-tag .arch-post-p { margin: 0; padding: .25em; border-left: 1px solid transparent; }

@media screen and (min-width: 23em) { .dc-archive .arch-post-p, .dc-tag .arch-post-p { padding: .25em .5em; } }

@media screen and (min-width: 40em) { .dc-archive .arch-post-p, .dc-tag .arch-post-p { border-left-color: #a9a9b0; } }

.dc-archive .arch-post-adb, .dc-tag .arch-post-adb { width: 100%; padding-left: 3em; color: #fff; background: #444 url(img/avatar.jpg) no-repeat -1px; background-size: contain; border-left-color: transparent; }

@media screen and (min-width: 40em) { .dc-archive .arch-post-adb, .dc-tag .arch-post-adb { width: 19.75em; } }

.dc-archive .arch-post-day, .dc-tag .arch-post-day { width: 6.75em; color: #fff; background-color: #444; border-left-color: transparent; }

.dc-archive .arch-post-author, .dc-tag .arch-post-author { width: 11.75em; }

@media screen and (min-width: 23em) { .dc-archive .arch-post-author, .dc-tag .arch-post-author { width: 13em; } }

.dc-archive .arch-post-title, .dc-tag .arch-post-title { min-width: 19em; border-top: 1px solid #a9a9b0; flex: 1; }

@media screen and (min-width: 40em) { .dc-archive .arch-post-title, .dc-tag .arch-post-title { border-top-color: transparent; } }

.notes .arch-post-author, .notes .arch-post-day { background-color: #444444; color: white; }

.staff_1 .arch-post-author, .staff_1 .arch-post-day { background-color: #325ac3; color: white; }

.staff_2 .arch-post-author, .staff_2 .arch-post-day { background-color: #3a539b; color: white; }

.staff_3 .arch-post-author, .staff_3 .arch-post-day { background-color: #406098; color: white; }

.staff_4 .arch-post-author, .staff_4 .arch-post-day { background-color: #007faa; color: white; }

.staff_5 .arch-post-author, .staff_5 .arch-post-day { background-color: #16405B; color: white; }

.staff_6 .arch-post-author, .staff_6 .arch-post-day { background-color: #0A3055; color: white; }

.staff_7 .arch-post-author, .staff_7 .arch-post-day { background-color: #00008B; color: white; }

.staff_8 .arch-post-author, .staff_8 .arch-post-day { background-color: indigo; color: white; }

.room_1 .arch-post-author, .room_1 .arch-post-day { background-color: #1e824c; color: white; }

.room_2 .arch-post-author, .room_2 .arch-post-day { background-color: #008000; color: white; }

.room_3 .arch-post-author, .room_3 .arch-post-day { background-color: #005500; color: white; }

.room_4 .arch-post-author, .room_4 .arch-post-day { background-color: #20603C; color: white; }

.room_5 .arch-post-author, .room_5 .arch-post-day { background-color: #005051; color: white; }

.room_6 .arch-post-author, .room_6 .arch-post-day { background-color: #345A5E; color: white; }

.room_7 .arch-post-author, .room_7 .arch-post-day { background-color: #9b59b6; color: white; }

.room_8 .arch-post-author, .room_8 .arch-post-day { background-color: #b500b5; color: white; }

.room_9 .arch-post-author, .room_9 .arch-post-day { background-color: #913D88; color: white; }

.room_10 .arch-post-author, .room_10 .arch-post-day { background-color: #7928a1; color: white; }

.room_11 .arch-post-author, .room_11 .arch-post-day { background-color: #8B008B; color: white; }

.room_12 .arch-post-author, .room_12 .arch-post-day { background-color: #5D3754; color: white; }

.room_13 .arch-post-author, .room_13 .arch-post-day { background-color: #a74165; color: white; }

.room_15 .arch-post-author, .room_15 .arch-post-day { background-color: #b11030; color: white; }

.room_16 .arch-post-author, .room_16 .arch-post-day { background-color: #aa2e00; color: white; }

.room_17 .arch-post-author, .room_17 .arch-post-day { background-color: #d32f2f; color: white; }

.room_18 .arch-post-author, .room_18 .arch-post-day { background-color: #c94900; color: white; }

.room_19 .arch-post-author, .room_19 .arch-post-day { background-color: #aa5535; color: white; }

.room_14 .arch-post-author, .room_14 .arch-post-day { background-color: #806c00; color: white; }

.room_20 .arch-post-author, .room_20 .arch-post-day { background-color: #802200; color: white; }

.Notes-de-la-Direction .arch-post-day { background-color: #444444; }

.toggle-wrapper { text-align: center; }

.sg-toggle-comments { width: 20em; font-weight: bold; background-color: white; }

.sg-toggle-comments:focus { outline: thin dotted; }

.sg-toggle-comments { padding: .5em 1em; margin-bottom: 1em; border-radius: 0; }

.sg-toggle-comments:focus, .sg-toggle-comments:hover { background: #dcedc8; }

.sg-toggle-comments:before { content: "Masquer les "; }

.sg-toggle-comments.is-closed:before { content: "Afficher les "; }

.sg-comment { display: none; padding: .5em 1em; border-top: 1px solid #a9a9b0; padding: .5em 1em; width: 100%; }

.sg-comment p { margin: 0; }

.sg-comment.is-visible { display: block; }

.dc-404 { background-color: #dcedc8; }

.dc-404 #main { max-width: 34em; padding: 2em; margin: 3% auto 20%; background-color: #fff; border: 1rem solid transparent; box-shadow: 0 0.75rem 0.75rem rgba(0, 0, 0, 0.2); background-image: linear-gradient(white, white), linear-gradient(45deg, #444444 0%, #444444 3.44828%, #325ac3 3.44828%, #325ac3 6.89655%, #3a539b 6.89655%, #3a539b 10.34483%, #406098 10.34483%, #406098 13.7931%, #007faa 13.7931%, #007faa 17.24138%, #16405B 17.24138%, #16405B 20.68966%, #0A3055 20.68966%, #0A3055 24.13793%, #00008B 24.13793%, #00008B 27.58621%, indigo 27.58621%, indigo 31.03448%, #1e824c 31.03448%, #1e824c 34.48276%, #008000 34.48276%, #008000 37.93103%, #005500 37.93103%, #005500 41.37931%, #20603C 41.37931%, #20603C 44.82759%, #005051 44.82759%, #005051 48.27586%, #345A5E 48.27586%, #345A5E 51.72414%, #9b59b6 51.72414%, #9b59b6 55.17241%, #b500b5 55.17241%, #b500b5 58.62069%, #913D88 58.62069%, #913D88 62.06897%, #7928a1 62.06897%, #7928a1 65.51724%, #8B008B 65.51724%, #8B008B 68.96552%, #5D3754 68.96552%, #5D3754 72.41379%, #a74165 72.41379%, #a74165 75.86207%, #b11030 75.86207%, #b11030 79.31034%, #aa2e00 79.31034%, #aa2e00 82.75862%, #d32f2f 82.75862%, #d32f2f 86.2069%, #c94900 86.2069%, #c94900 89.65517%, #aa5535 89.65517%, #aa5535 93.10345%, #806c00 93.10345%, #806c00 96.55172%, #802200 96.55172%, #802200 100%); background-repeat: no-repeat; background-size: 100% 100%, 100% 200%; background-position: 0 0, 0 100%; background-origin: padding-box, border-box; }

.dc-404 #main::after { content: ""; display: block; width: 100%; height: 5em; background-image: linear-gradient(90deg, #325ac3 0%, #325ac3 3.57143%, #3a539b 3.57143%, #3a539b 7.14286%, #406098 7.14286%, #406098 10.71429%, #007faa 10.71429%, #007faa 14.28571%, #16405B 14.28571%, #16405B 17.85714%, #0A3055 17.85714%, #0A3055 21.42857%, #00008B 21.42857%, #00008B 25%, indigo 25%, indigo 28.57143%, #1e824c 28.57143%, #1e824c 32.14286%, #008000 32.14286%, #008000 35.71429%, #005500 35.71429%, #005500 39.28571%, #20603C 39.28571%, #20603C 42.85714%, #005051 42.85714%, #005051 46.42857%, #345A5E 46.42857%, #345A5E 50%, #9b59b6 50%, #9b59b6 53.57143%, #b500b5 53.57143%, #b500b5 57.14286%, #913D88 57.14286%, #913D88 60.71429%, #7928a1 60.71429%, #7928a1 64.28571%, #8B008B 64.28571%, #8B008B 67.85714%, #5D3754 67.85714%, #5D3754 71.42857%, #a74165 71.42857%, #a74165 75%, #b11030 75%, #b11030 78.57143%, #aa2e00 78.57143%, #aa2e00 82.14286%, #d32f2f 82.14286%, #d32f2f 85.71429%, #c94900 85.71429%, #c94900 89.28571%, #aa5535 89.28571%, #aa5535 92.85714%, #806c00 92.85714%, #806c00 96.42857%, #802200 96.42857%, #802200 100%); }

.dc-404 #content-info { font-size: 1.125em; }

.dc-404 #content-info h2 { margin-bottom: 1em; }

.dc-404 .content-inner { padding: 5% 0; }

.dc-404 #gotop { display: none; }

/** -------------------------------------------------- Spécialités print --------------------------------------------------- */
@media print { * { color: #000 !important; background: transparent !important; box-shadow: none !important; text-shadow: none !important; }
  html { font-size: 8pt; line-height: 1.2; }
  body { font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"; font-size: 12pt; }
  p { orphans: 3; widows: 3; }
  h1, h2, h3, h4, h5, h6, caption { break-after: avoid; page-break-after: avoid; }
  img { filter: contrast(150%) grayscale(100%) brightness(120%); }
  a { color: #444 !important; }
  #main a[href]:not([href^="/"]):not([href^="#"])::after { font-size: .8em; content: " (" attr(href) ")"; }
  #main a[href^="/"]::after { font-size: .8em; content: " (https://auberge.des-blogueurs.org" attr(href) ")"; }
  .go-a11y, #prelude, #gotop, #footer, #a42-ac, .send-ping, #comments-feed, .category-feed { display: none !important; }
  #page { max-width: 62em; }
  .site-title { font-size: 30pt; }
  .header, .dc-home-first .header { height: 80pt; }
  .post .post-header, .dc-archive .arch-post, .dc-archive .arch-post p, .dc-archive .arch-post-title a { padding-left: 0 !important; border: 0; }
  .post h2, #content-info h2 { font-size: 18pt; }
  .simple .post-title { margin-bottom: 30pt; }
  .header-info.author { font-size: 1em !important; }
  .dc-page .post-excerpt { border-color: #999; }
  .dc-archive .arch-post { border-bottom: 1px solid; }
  .dc-archive h4 { margin: 2em 0 1em; }
  .dc-archive .arch-post-adb { width: 20em; } }
