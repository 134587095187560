// ------------------ VARIABLES ------------------- //

// ========================================================================== //
// =Sizes
// ========================================================================== //

$base-grid: .5rem;
$base-font-size: 1rem;
$base-line-height: 1.5;

// ========================================================================== //
// =Breakpoints
// ========================================================================== //

$x-small-screen: 23em;    // 368
$small-screen:   30em;    // 480
$medium-screen:  40em;    // 640
$large-screen:   60em;    // 960
$x-large-screen:  80em;    // 1280

// ========================================================================== //
// =Typographie
// ========================================================================== //

$font-family-sans-serif : -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol" !default;
$font-family-serif      : Georgia, "Times New Roman", Times, serif !default;
$font-family-monospace  : SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !default;

$sans-serif: $font-family-sans-serif !default;
$serif: $font-family-serif !default;
$monospace: $font-family-monospace !default;

// ========================================================================== //
// =Couleurs
// ========================================================================== //

$body-bg: #fff;
$body-color: #000;

// Palette de base
$gray: #a9a9b0;
$gray-light: #f5f7f7;
$field-border: #909090;
$red: #d52125;
$pink: #aa00aa;
$paleyellow: #fcedc7;
$palegreen: #dcedc8;

$link: $red;
$link2: $pink;
$postit: $paleyellow;
$credits-bg: $palegreen;
  // staff_1:  #3455db,

$room_color: (
  notes:    #444444,
  staff_1:  #325ac3,
  staff_2:  #3a539b,
  staff_3:  #406098,
  staff_4:  #007faa,
  staff_5:  #16405B,
  staff_6:  #0A3055,
  staff_7:  #00008B,
  staff_8:  indigo,
  room_1:   #1e824c,
  room_2:   #008000,
  room_3:   #005500,
  room_4:   #20603C,
  room_5:   #005051,
  room_6:   #345A5E,
  room_7:   #9b59b6,
  room_8:   #b500b5,
  room_9:   #913D88,
  room_10:  #7928a1,
  room_11:  #8B008B,
  room_12:  #5D3754,
  room_13:  #a74165,
  room_15:  #b11030,
  room_16:  #aa2e00,
  room_17:  #d32f2f,
  room_18:  #c94900,
  room_19:  #aa5535,
  room_14:  #806c00,
  room_20:  #802200
);

$notes: map-get($room_color,"notes");
