.dc-404 {

  background-color: #dcedc8;

  #main {
    max-width: 34em;
    padding: 2em;
    margin: 3% auto 20%;
    background-color: #fff;
    border: 1rem solid transparent;
    box-shadow: 0 .75rem .75rem rgba(0, 0, 0, 0.2);
    background-image:
      linear-gradient(white, white),
      linear-gradient(45deg,prettyflag($room_color));
    background-repeat: no-repeat;
    background-size: 100% 100%, 100% 200%;
    background-position: 0 0, 0 100%;
    background-origin: padding-box, border-box;
  }

  #main::after {
    content: "";
    display: block;
    width: 100%;
    height: 5em;
    background-image: linear-gradient(90deg, prettyflag($room_color, ('notes')));
  }

  #content-info {
    font-size: 1.125em;

    h2 {
      margin-bottom: 1em;
    }
  }

  .content-inner {
    padding: 5% 0;
  }

  #gotop {
    display: none;
  }
}
