html {
  color: $body-color;
  font-family: $sans-serif;
  font-size: 100%;
  line-height: $base-line-height;
}

body {
  font-size: $base-font-size;

  @media screen and (min-width: $large-screen) {
    font-size: 1.125rem;
  }
}

// classe générique pour cacher visuellement des contenus
// mais qu'ils soient lus par les aides techniques
.visually-hidden {
  @include visually-hidden;
}

// titres de page (archive, category, page)

#content-info {
  background: rgba(255,255,255,.5);
  margin-bottom: 1rem;
  padding-bottom: .25rem;
  text-align: center;

  p {
    margin: 0 0 .25rem;
  }
}

#content-info h2 {
  font-family: $serif;
  font-style: italic;
  font-size: 1.75rem;
}

// séparateurs (date sur home, nb commentaires, comment-form sur post)

.chapter-separator {
  overflow: hidden;
  margin-bottom: 1em;
  text-align: center;
}

.chapter-title {
  overflow: hidden;
  font-weight: normal;
}

.chapter-title::before,
.chapter-title::after {
  position: relative;
  display: inline-block;
  width: 50%;
  height: 1px;
  background-color: #000;
  vertical-align: middle;
  content: "";
}

.chapter-title:before {
  right: 1em;
  margin-left: -50%;
}

.chapter-title::after {
  left: 1em;
  margin-right: -50%;
}

.dc-home .chapter-title {
  margin-bottom: 2rem;
  font-size: 1.25rem;
}

// petits séparateurs (trois astérisques centrés)

hr.couillard {
  display: block;
  clear: both;
  height: 0;
  padding: 0 0 1.5em;
  border: 0;
  font-family: $sans-serif;
  text-align: center;
  font-size: 1rem;
  line-height: 1;
}

hr.couillard::after {
  content: "\273D \273D \273D";
  height: 0;
  letter-spacing: 1em;
  color: darken($postit, 60%);
}


// liens
a {
  color: $link;
  text-decoration: none;
  transition: .3s;

  &:link,
  &:visited {
    border-bottom: 1px solid $gray;
  }

  &:visited {
    border-bottom-style: dotted;
  }

  &:hover,
  &:active {
    border-bottom: 1px solid $link;
  }

  &:focus {
    color: #fff;
    background-color: $link;
  }
}

// icones
.icon {
  display: inline-block;
  width: 1em;
  height: 1em;
  margin-top: -.15em;
  margin-right: .5rem;
  vertical-align: middle;

  fill: currentColor;
}

.icon-rss,
.icon-mastodon,
.icon-dotclear,
.icon.icon-group {
  width: 1.25em;
  height: 1.25em;
  margin-right: .4rem;
}

.icon-dotclear {
  margin-left: .125rem;

  fill: #1b7742;
}

// si on veut styler au sein d'un élément du sprite, il faut styler
// directement l'id du sélecteur et la class du sous-élément.
// Ne fonctionne pas en sélecteur de type .icon-dotclear #dotclear […].
//
// #dotclear .cdc-icon-nerve {
//   fill: red;
// }

.icon-search {
  width: 1.5em;
  height: 1.5em;
}

/*
  un comportement plus doux pour le retour en haut de page
  sans forcer les préférences de l'utilisateur
  source : https://moderncss.dev/pure-css-smooth-scroll-back-to-top/
*/
@media screen and (prefers-reduced-motion: no-preference) {
  html,
  body {
    scroll-behavior: smooth;
  }
}

#gotop {
  margin: 0;
  text-align: center;
}

// boutons et formulaires
.buttons {
  font-size: 1.25rem;
  text-align: right;
}

button,
.button {
  display: inline-block;
  margin: 0 2px;
  padding: .25rem 1rem;
  color: #42434e;
  background-color: $gray-light;
  border: 1px solid $field-border;
  border-radius: .25rem;
  box-shadow: 3px 3px 4px 0 rgba(0,0,0,.15);
  font-size: 1em;
  font-weight: normal;
  line-height: 1.5;
  text-align: center;
  vertical-align: middle;
  white-space: nowrap;
  transition: all 300ms ease;

  user-select: none;

  &:hover {
    transform: scale(1.05);
    border-color: currentColor;
  }

  &:active {
    transform: scale(.97);
  }

  &::-moz-focus-inner {
    padding: 0;
    border: none;
  }
}

.submit {
  color: #fff;
  background-color: $link2;
  border-color: darken($link2,5%);

  &:focus {
    background-color: $body-color;
  }
}

.preview {
  margin-right: 1rem;
  border-color: $link2;
}

input,
textarea {
  display: inline-block; // for safari
  padding: .5rem .5rem;
  border: 1px solid $field-border;

  &:focus {
    border-color: $link2;
    box-shadow: 0 0 0 2px rgba(150,0,150, .25);
  }
}

q::before, cite::before {
  content: open-quote;
}
q::after, cite::after {
  content: close-quote;
}
:lang(en), article:lang(en) {
  quotes: '“ ' ' ”' '‘' '’';
}
:lang(fr), article:lang(fr) {
  quotes: '« ' ' »' '‘' '’';
}
:lang(es), article:lang(es) {
  quotes: '“' '”' '‘' '’';
}
:lang(de), article:lang(de) {
  quotes: '» ' ' «' '‘' '’';
}
:lang(en)>q:not(:lang), :lang(en)>cite:not(:lang) {
  quotes: '“ ' ' ”' '‘' '’';
}
:lang(fr)>q:not(:lang), :lang(fr)>cite:not(:lang) {
  quotes: '« ' ' »' '‘' '’';
}
:lang(es)>q:not(:lang), :lang(es)>cite:not(:lang) {
  quotes: '“' '”' '‘' '’';
}
:lang(de)>q:not(:lang), :lang(de)>cite:not(:lang) {
  quotes: '» ' ' «' '‘' '’';
}
blockquote {
  background-color: lighten($palegreen,5%);
  padding: .25em 1em;
}
