// layout affichage simple
// -----------------------

.post {
  background-color: $gray-light;

  h3, h4 {
    font-family: $serif;
    font-weight: 400;
  }
}

.simple,
.post-feedback {
  max-width: 50em;
  margin: 2rem auto;

  @media screen and(min-width: $medium-screen) {
    margin-top: 4rem;
  }
}

.simple {
  box-shadow: 4px -4px 12px rgba(0,0,0,0.1);

  .post-content h2 {
    font-size: 1.75em;
    font-family: $serif;
    font-style: italic;
    text-align: center;
    margin-top: 3rem;
  }

  .post-content {
    hr {
      display: block;
      clear: both;
      height: 0;
      padding: 0 0 1.5em;
      border: 0;
      font-family: $sans-serif;
      text-align: center;
      font-size: 1.25rem;
      line-height: 1;
    }

    hr::after {
      content: "\273D \273D \273D";
      height: 0;
      letter-spacing: 1em;
      color: #666;
    }

    pre,
    code {
      font-family: $monospace;
      font-size: .875em;
    }

    aside strong {
      font-family: $monospace;
      font-weight: 500;
    }
  }

  .footnotes {
    margin-top: 2.5em;
    padding-top: 1em;
    border-top: 1px dashed #666;
    font-size: .925rem;

    h4 {
      font-size: 1.125em;
      font-family: $sans-serif;
      font-weight: 500;
    }
  }
}

.post-full { // container excerpt & content billet seul
  padding: 0 .5rem 1rem;

  @media screen and(min-width: $medium-screen) {
    padding: 0 7% 2em;
  }

  img {
    box-shadow: 4px -4px 12px rgba(0, 0, 0, 0.1);
    padding: .75em .75em .75em .75em;
    background: white;
  }

  figure {

    img {
      padding-bottom: 2.5em;
    }

    figcaption {
      margin-top: -2.75em;
      padding-left: 1em;
      font-family: $serif;
      font-size: .8333em;
      font-style: italic;
      color: $body-color;
    }
  }
}

// entête
// -----------------------

.post-banner {
  display: flex;
  flex-wrap: wrap;

  .post-header  {
    width: 100%;
    font-size: .925rem;

    @media screen and(min-width: 42em) {
      width: auto;
      min-width: 17em;
      font-size: 1rem;
    }
  }

  .post-title {
    flex: 1;
    align-self: center;
    margin: 1rem 0;
    padding: 0 1rem;
    line-height: 1.25;
    text-align: center;
    font-family: $serif;
    font-size: 2rem;
    font-weight: normal;
    font-style: italic;

    @media screen and(max-width: 42em) {
      font-size: 1.75rem;
      margin-top: 3rem;
      margin-bottom: 1rem;
      padding: 0 .5rem;
    }
  }

  @media screen and(min-width: 42em) {
    margin-bottom: 3rem;
  }

  @media screen and(min-width: $large-screen) {
    margin-bottom: 5rem;
  }
}

.author,
.room,
.date {
  margin: 0;
}

.author {
  font-size: 1.5em;
}

.header-info.author {
  line-height: 1.25em;
  margin-top: .125em;
  margin-bottom: .125em;
}

.room,
.date {
  font-size: 1em;
}


// personnalisation selon l'auteur
.post-header {
  background-color: #444;
  color: #fff;
  padding: .5em 1.5em;

  &.Notes-de-la-Direction {
    padding-left: 5em;
    background: $notes url('img/avatar.jpg') no-repeat .75rem 50%;
    background-size: 3.5em auto;
  }
}

@each $nom, $valeur in $room-color {
  .post-header.#{$nom} {
    background-color: $valeur;
  }
}

@each $nom, $valeur in $room-color {
  .hour.#{$nom} {
    border-color: $valeur;
  }
}

.hour,
.date span {
  font-size: .875em;
  padding: 2px .5em;
  border-radius: .25em;
  border: 1px solid;
}

.hour {
  font-weight: bold;
  background: #fff;
  margin-right: .25em;
}

.date span {
  background-color: transparent;
  border-color: rgba(255,255,255,.6);
  margin-left: .5em;
}

// spécialité distribution en cartes
// --------------------------------

// layout
.posts-group {
  width: 100%;

  @media screen and(min-width: $medium-screen) {
    display: flex;
    flex-wrap: wrap;
  }

  .post {
    position: relative; // pour permettre le clic sur toute la zone
    margin-bottom: 2rem;
    border-radius: .25rem;
    box-shadow: 0 10px 6px -6px rgba(0,0,0,0.3);
    transition: box-shadow 0.2s ease-in-out;

    @media screen and(min-width: $medium-screen) and(max-width: $x-large-screen) {
      flex: 0 48.5%;
      margin-right: 2%;
      overflow: hidden;

      &:nth-child(2n+2) {
        margin-right: 0;
      }
    }

    @media screen and(min-width: $x-large-screen) {
      flex: 0 31%;
      margin-bottom: 3rem;
      margin-right: 3.5%;
      overflow: hidden;

      &:nth-child(3n+3) {
        margin-right: 0;
      }
    }

    &:hover {
      box-shadow: 0 6px 10px -4px rgba(0,0,0,0.5);
    }
  }

  .post-header {
    border-radius: .25rem .25rem 0 0;
  }

  .post-content {
    @include discrete-link;

    padding: .25em 1em;
    height: 100%; // cf overflow: hidden; sur le parent (.post)

    @media screen and(min-width: $small-screen) {
      padding: .25em 1.5em;
    }

    a {
      font-weight: 600;
    }

  }

  .post-content a::after {
    display: block;
    position: absolute;
    content: "";
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }
}

.dc-home .Notes-de-la-Direction + .post-content,
.dc-search .Notes-de-la-Direction + .post-content {
  background-color: $postit;
  box-shadow: 0 .75rem .75rem rgba(0, 0, 0, 0.2);
}

// spécialité billet seul
// --------------------------------

.dc-post {
  .Notes-de-la-Direction {
    background-size: 5.25em;
    padding-left: 6.5em;
  }
}

// spécialité contactMe
// --------------------------------

.dc-contactme .post {
  padding: 1rem;

  @media screen and(min-width: $small-screen) {
    padding: 1rem 2rem 2rem;
  }
}
