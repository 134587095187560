.footer {
  @include discrete-link(#000);

  overflow: hidden;
  margin-top: 4rem;

  ul {
    @include list-unstyled;

    margin: 0 auto;
  }

  h2 {
    margin: 0 0 .5rem;
    font-size: 1.5rem;
  }

  h3 {
    margin: 1rem 0 0;
    font-family: $serif;
    font-size: 1.5rem;
    font-weight: normal;
  }
}

.footer-top {
  padding: 1rem .5rem;
  background: #fff url(img/header-auberge.jpg) center no-repeat;
  background-size: cover;
  font-size: 1.25rem;
  font-weight: bold;
  text-align: center;

  @media screen and (min-width: $small-screen) {
    padding: 1rem 1rem;
  }

  @media screen and (min-width: $large-screen) {
    margin: 1rem 3rem;
    padding: 1rem 2rem;
  }

  .container {
    padding: .5rem;
    background-color: rgba(255, 255, 255, .7);
  }

  p {
    margin-top: 0;
  }
}

.infos-auberge {
  @media screen and(min-width: $small-screen) {
    ul {
      @include list-inline;
    }
  }
}

.footer-search {
  max-width: 40em;
  margin: 2rem .5em 0;
  line-height: 2;

  @media screen and (min-width: $large-screen) {
    margin: 3rem auto 0;
  }
}

h2.search-title {
  margin-bottom: 0;
  font-size: 1rem;
}

.search-form .input-search-group {
  display: flex;
  margin: 0;

  input,
  button {
    border: 1px solid rgba(147, 128, 108, .5);
  }

  input[type="search"] {
    display: block;
    margin-right: -1px;
    padding: .5em;

    flex: 1;
  }

  .button {
    display: block;
    width: 5em;
    border-radius: 0 .25em .25em 0;
  }
}

.footer-bottom {
  padding: 1rem .5rem;
  line-height: 2;

  @media screen and (min-width: $medium-screen) {
    display: flex;

    justify-content: space-between;

    section {
      min-width: 13em;
    }
  }

  @media screen and (min-width: $large-screen) {
    margin: 1rem 3rem;
  }
}

.feeds {
  margin: 0;
  padding-top: .5em;
  border-top: 1px solid $gray;
  text-align: center;

  li {
    display: inline-block;
    margin: 0 1em;
  }
}

.quality {
  margin-bottom: 0;
  margin-top: 3rem;
  padding: .5rem;
  background-color: lighten(#dcedc8, 7%);
  border-top: 1px solid darken(#dcedc8, 40%);
  text-align: center;
  font-family: $serif;

  a:link,
  a:visited {
    border-bottom-color: #aaa;
  }
}

.footer-credits {
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: .5rem;
  background-color: #dcedc8;
  overflow: hidden;

  a:link,
  a:visited {
    border-bottom-color: #aaa;
  }

   > p {
    margin: 0;
  }

  @media screen and(min-width: $small-screen) {
    flex-direction: row;
    padding-bottom: 3.5em;

    justify-content: space-between;

    .legal {
      text-align: right;
    }
  }

  @media screen and (min-width: $large-screen) {
    padding: 1rem;
  }
}
