// mixin svg fallback
@mixin svg(
    $file-name,
    $css-img-path: $css-img-path) {
    background-image: inline-image($file-name+'.png');
    background-image: inline-image($file-name+'.svg'), none;
}

@mixin bg-with-svg(
    $file-name,
    $css-img-path: $css-img-path,
    $repeat: no-repeat,
    $position: 50% 50%,
    $bg-color: transparent) {
    background: inline-image($file-name+'.png') $repeat, $position, $bg-color;
    background-image: inline-image($file-name+'.svg'), none;
}

@mixin visually-hidden {
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    padding: 0;
    overflow: hidden;
    clip: rect(1px,1px,1px,1px);
    -webkit-clip-path: inset(50%);
          clip-path: inset(50%);
    border: 0;
    white-space: nowrap;
}

@mixin visually-hidden-focusable {
  &:active,
  &:focus {
    position: static;
    width: auto;
    height: auto;
    margin: 0;
    overflow: visible;
    clip: auto;
    -webkit-clip-path: none;
            clip-path: none;
    white-space: normal;
  }
}

@mixin list-unstyled {
  padding-left: 0;
  list-style: none;
}

@mixin list-inline($padding: 2rem) {
  @include list-unstyled;

  > li {
    display: inline-block;

    &:not(:last-child) {
      margin-right: $padding;
    }
  }
}

@mixin discrete-link($color:$body-color, $contrast-color:$link, $focus-color:#fff) {
  a {

    &:link,
    &:visited {
      border-bottom: 1px solid transparent;
      color: $color;
    }

    &:hover,
    &:active,
    &:visited:hover,
    &:visited:active {
      border-bottom-color: $contrast-color;
    }

    &:focus {
      background-color: $contrast-color;
      color: $focus-color;
    }
  }
}


/*
  Génère une liste de couleurs utilisées pour un gradient de 0 à 100%
  chaque couleur est répétée deux fois pour obtenir des aplats.
  Si $ignore est fourni, il contient une liste de clés à ignorer.

  Utilisation:
  1. Créer une map de couleurs $flag_color
      ou donner à $flag_color une map existante
      ou indiquer la map à utiliser en premier paramètre de la fonction
  2. La fonction crée les valeurs à renseigner pour la propriété gradient-color.

  Exemple:
    background: linear-gradient(90deg, prettyflag($room_color, ('notes','room_14')));
*/

$flag_color: () !default;

@function prettyflag($map: $flag_color, $ignore: ()) {
  $i: 0;
  $len: length($map) - length($ignore);
  $ret: null;

  @each $key, $value in $map {
    @if not index($ignore, $key) {
      @if ($ret) {
        // S'il y a déjà des couleurs, on ajoute une virgule
        $ret: unquote('#{$ret},');  // On retire les quotes après avoir ajouté une ,
      }

      // Début : calcul du % de début pour la couleur;
      $step: (100 / $len) * ($i);
      $step: unquote('#{$step}%');  // On retire les quotes après avoir ajouté un %
      $ret: $ret #{$value} #{$step};

      $ret: unquote('#{$ret},');  // On retire les quotes après avoir ajouté une ,

      // Fin : calcul du % de fin pour la couleur;
      $step: (100 / $len) * ($i + 1);
      $step: unquote('#{$step}%');  // On retire les quotes après avoir ajouté un %
      $ret: $ret #{$value} #{$step};

      $i: $i + 1;
    }
  }
  @return $ret;
}
