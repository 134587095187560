.dc-page {
  .post-header {
    display: none;
  }

  .post-title {
    margin-top: 1em;

    @media screen and(min-width: $small-screen) {
      margin-top: 2em;
    }
  }

  .post-excerpt {
    margin-bottom: 2em;
    padding: .5rem 1rem;
    background-color: $postit;
    border: 6px double darken($postit, 20%);
    border-radius: 1em;

    a {
      color: darken($link,10%)
    }

    @media screen and(min-width: $small-screen) {
      padding: .5rem 2rem;
    }
  }

  .post-banner {
    margin-bottom: 2em;
  }

  h3 {
    font-weight: 600;
  }

  h4 {
    margin-top: 2em;
    padding-bottom: .5em;
    font-weight: 600;
    color: darken($postit, 60%);
  }

  strong {
    font-weight: 600;
  }

  .post-content ul > li {
    margin-bottom: .5em;
  }

  .chapter-separator {
    margin-top: 2em;
  }
}
