.dc-archive {
  .simple {
    font-size: 1rem;
  }
}

.dc-archive,
.dc-tag {
  #main {
    @media screen and(max-width: $medium-screen) {
      padding: 0;
    }
  }

  .simple {
    max-width: 58em;
    margin-top: 1em;
    padding: 1em .25em;
    background-color: #fff;

    @media screen and(min-width: $x-small-screen) {
      padding-left: .5em;
      padding-right: .5em;
    }

    @media screen and(min-width: $medium-screen) {
      padding-left: 1em;
      padding-right: 1em;
    }

    @media screen and(min-width: $large-screen) {
      padding-left: 2em;
      padding-right: 2em;
    }

    @media screen and(min-width: $x-large-screen) {
      padding: 2em;
    }
  }

  h4 {
    margin-top: 2rem;
    margin-bottom: 1rem;
    font-family: $sans-serif;
    font-size: 1.125rem;
    font-weight: 600;
    text-align: center;
  }

  .one-day-list {
    @include list-unstyled;

    margin-bottom: 4em;
  }

  .arch-post {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    overflow: hidden;
    margin-bottom: 1em;
    background-color: $gray-light;
    border: 1px solid $gray;

    a::after {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      content: "";
    }

    a:hover::after {
      border: 1px solid $link2;
    }
  }

  .arch-post-p {
    margin: 0;
    padding: .25em;
    border-left: 1px solid transparent;

    @media screen and(min-width: $x-small-screen) {
      padding: .25em .5em;
    }

    @media screen and(min-width: $medium-screen) {
      border-left-color: $gray;
    }
  }

  .arch-post-adb {
    width: 100%;
    padding-left: 3em;
    color: #fff;
    background: #444 url(img/avatar.jpg) no-repeat -1px;
    background-size: contain;
    border-left-color: transparent;

    @media screen and(min-width: $medium-screen) {
      width: 19.75em;
    }
  }

  .arch-post-day {
    width: 6.75em;
    color: #fff;
    background-color: #444;
    border-left-color: transparent;
  }

  .arch-post-author {
    width: 11.75em;

    @media screen and(min-width: $x-small-screen) {
      width: 13em;
    }
  }

  .arch-post-title {
    min-width: 19em;
    border-top: 1px solid $gray;

    flex: 1;

    @media screen and(min-width: $medium-screen) {
      border-top-color: transparent;
    }
  }
}

@each $nom,
$valeur in $room-color {
  .#{$nom} .arch-post-author,
  .#{$nom} .arch-post-day {
    background-color: $valeur;
    color: white;
  }
}

.Notes-de-la-Direction .arch-post-day {
  background-color: $notes;
}

// u-comments
// Permet d'afficher masquer des commentaires en 'post-it' pendant la phase de développement.

.toggle-wrapper {
  text-align: center;
}

.sg-toggle-comments {
  width: 20em;
  font-weight: bold;
  background-color: white;

  &:focus {
    outline: thin dotted;
  }
}

.sg-toggle-comments {
  padding: .5em 1em;
  margin-bottom: 1em;
  border-radius: 0;

  &:focus,
  &:hover {
    background: $palegreen;
  }

    &:before {
      content: "Masquer les "
    }

    &.is-closed {
      &:before {
        content: "Afficher les "
      }
    }
}

.sg-comment {
  display: none;
  padding: .5em 1em;
  border-top: 1px solid $gray;
  padding: .5em 1em;
  width: 100%;

  p {
    margin: 0;
  }
}

.sg-comment.is-visible {
  display: block;
}

