.post-feedback {
  @include discrete-link($link2);

  margin-bottom: 4rem;

  a:link,
  a:visited {
    border-bottom-color: $link2;
  }
}

.comments-list {
  @include list-unstyled;
}

.comment {
  margin-top: 2rem;
  margin-bottom: 2rem;
  padding-right: 1em;
  padding-left: 1em;
  border: 1px solid $link2;
  border-radius: .5em;
}

.comment.even {
  margin-left: 10%;
}

.comment.odd {
  margin-right: 10%;
}

@media screen and(min-width: $small-screen) {
  .comment.even {
    margin-left: 15%;
  }

  .comment.odd {
    margin-right: 15%;
  }
}

.comment.me {
  background-color: $palegreen;
}

.comment-info {
  font-family: $serif;
}

.comment-number {
  display: inline-block;
  width: 3em;
  height: 1.5em;
  margin-right: .5em;
  text-align: center;
}

.ping {
  position: relative;
  padding-right: 1em;
  padding-left: 1em;
  border: 2px solid #999;

  .comment-content p:first-child {
    margin-bottom: 0;
  }

  .comment-content p:nth-child(2) {
    margin-top: 0;
  }

  strong {
    font-family: $serif;
  }
}

#comments-feed {
  margin-bottom: 4rem;
  text-align: center;
}

// Formulaire
// spécificités (le reste est dans common)
// -----------------------


.comment-form {
  margin-bottom: 4rem;
}

.field {
  display: flex;
  line-height: 2;

  flex-wrap: wrap;

  label {
    display: block;
    width: 100%;
    margin-bottom: .25em;
    padding-left: 1em;
    background-color: $gray-light;
    border: 1px solid $field-border;
    white-space: nowrap;

    flex: none;

    @media screen and(min-width: $medium-screen) {
      width: 15em;
      margin-right: -1px;
      margin-bottom: 0;
    }
  }

  input,
  textarea {
    flex: 1;
  }
}

.field-content {
  display: block;
  margin-bottom: 0;
  margin-top: 1.5em;

   > div {
    margin-bottom: -1px;
    margin-left: 0;
    padding: .125rem;
    border: 1px solid $field-border;
  }

  label {
    width: 100%;
    margin-bottom: 1rem;
  }

  textarea {
    overflow: hidden;
    width: 100%;
    line-height: 1.5;
    border-color: $field-border;
  }

  .jstElements {
    padding-left: 1rem;
    padding-bottom: .5rem;

    button {
      border-color: transparent;

      &:focus,
      &:hover {
        border-color: $link2;
      }
    }
  }

  .jstElements.focus {
    padding-left: 1rem;
    padding-bottom: .5rem;
    border: 1px solid darken($credits-bg, 50%);
    background-color: $credits-bg;
  }

  .jstEditor textarea {
    border-color: transparent;
  }
}

.remember {
  margin-top: -.5rem;
  font-size: 1rem;

  @media screen and(min-width: $medium-screen) {
    padding-left: 17em;
  }
}

.form-help {
  margin-top: 0;
  margin-bottom: 0;
  font-size: 1rem;
}

#ping-url {
  color: #666;
  font-family: $monospace;
  font-size: 1rem;
}

#pr {
  margin-bottom: 2em;
  padding: 1em .5em 0;
  border: 4px solid transparent;
  box-sizing: border-box;
  background-image:
    linear-gradient(white, white),
    linear-gradient(180deg, orange, $link2 50%, orange);
  background-repeat: no-repeat;
  background-size: 100% 100%, 100% 200%;
  background-position: 0 0, 0 100%;
  background-origin: padding-box, border-box;
  animation: highlight .75s infinite alternate;

  .buttons {
    margin-bottom: .5rem;
  }
}

p#pr {
  padding-bottom: 1em;
  text-align: center;
}

@keyframes highlight {
  100% {
    background-position: 0 0, 0 0;
  }
}
