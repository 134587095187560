//
// Collapse (from Scampi library)
// --------------------------------------------------
// variables
$expand-symbol-closed: '\27A4';
$expand-symbol-open: '\2212';

.dc-page .post .buttons {
  margin-bottom: 0;
}

.js-expandmore-all {
  background-color: white;
  box-shadow: none;
  font-size: .875rem;
  margin-bottom: 0;
  border-radius: .75em;

  &:hover,
  &:focus {
    outline: thin dotted darken($palegreen, 40%);
    border-color: darken($palegreen, 60%);
  }
}

.js-expandmore {
  margin-bottom: 3em;
}

// question
.expandmore__button {
  position: relative;
  vertical-align: baseline;
  background: transparent;
  font-size: inherit;
  color: inherit;
  width: 100%;
  text-align: inherit;
  box-shadow: none;
  padding-left: 2em;
  margin-top: 1em;
  white-space: normal;
  user-select: text;

  &:hover,
  &:active {
    transform: none;
    border-color: darken($palegreen, 40%);
  }

  &[aria-expanded="true"],
  &:hover,
  &:focus {
    background-color: $palegreen;
    border-color: darken($palegreen, 40%);
  }

  &[aria-expanded="true"]:hover {
    background-color: $paleyellow;
    border-color: darken($paleyellow, 40%);
  }

  &:focus {
    outline: thin dotted darken($palegreen, 40%);
    border-color: darken($palegreen, 40%);
  }
}

// // Exemple de règles pour visualiser la fonctionalité
// // plier /déplier

.js-expandmore-title {
}

.js-expandmore-panel {
  padding: 0 1.5em;
}

.expandmore__symbol {
  font-size: .925em;
  line-height: 1;
  position: absolute;
  top : .5em;
  left: .5em;
}

.expandmore__symbol:before {
  content : $expand-symbol-closed;
}

.expandmore__button[aria-expanded=true] .expandmore__symbol:before ,
.expandmore__button[data-expanded=true] .expandmore__symbol:before {
  content : $expand-symbol-open;
}

// bouton "tout déplier"
.no-js .js-expandmore-all {
  display: none;
}


@media print {
  .js-expandmore-all {
    display: none;
  }
  .js-expandmore-panel[hidden] {
    display: block;
  }
}
