@media print {
  // basiques
  * {
    color: #000 !important;
    background: transparent !important;
    box-shadow: none !important;
    text-shadow: none !important;
  }

  html {
    font-size: 8pt;
    line-height: 1.2;
  }

  body {
    font-family: $sans-serif;
    font-size: 12pt;
  }

  p {
    orphans: 3;
    widows: 3;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  caption {
    break-after: avoid;
    page-break-after: avoid;
  }

  img {
    filter: contrast(150%) grayscale(100%) brightness(120%);
  }

  a {
    color: #444 !important;
  }

  #main a[href]:not([href^="/"]):not([href^="#"])::after {
    font-size: .8em;
    content: " (" attr(href) ")";
  }

  #main a[href^="/"]::after {
    font-size: .8em;
    content: " (https://auberge.des-blogueurs.org" attr(href) ")";
  }

  // auberge

  // blocs à ne pas imprimer
  .go-a11y,
  #prelude,
  #gotop,
  #footer,
  #a42-ac,
  .send-ping,
  #comments-feed,
  .category-feed {
    display: none !important;
  }

  // mise en forme
  #page {
    max-width: 62em;
  }

  .site-title {
    font-size: 30pt;
  }

  .header,
  .dc-home-first .header {
    height: 80pt;
  }

  .post .post-header,
  .dc-archive .arch-post,
  .dc-archive .arch-post p,
  .dc-archive .arch-post-title a {
    padding-left: 0 !important;
    border: 0;
  }

  .post h2,
  #content-info h2 {
    font-size: 18pt;
  }

  .simple .post-title {
    margin-bottom: 30pt;
  }

  .header-info.author {
    font-size: 1em !important;
  }

  .dc-page .post-excerpt {
    border-color: #999;
  }

  // archives
  .dc-archive .arch-post {
    border-bottom: 1px solid;
  }

  .dc-archive h4 {
    margin: 2em 0 1em;
  }

  .dc-archive .arch-post-adb {
    width: 20em;
  }
}
