// header
.header {
  @include discrete-link($color:#000);
}

// lien d'accès rapide vers la page Accessibilité

a.go-a11y {
  border-color: transparent;
  @include visually-hidden;
}

.header a.go-a11y {
  @include visually-hidden-focusable;

  display: block;
  padding: .125em 1.5em;
  background-color: darken($credits-bg,55%);
  border-color: white;
  color: #fff;
}

.skip-links {
  @include list-inline($padding:0);

  margin: 0;
  padding-right: .5rem;
  font-size: .875rem;
  text-align: right;

  a {
    padding: 0 .125rem;
  }

  @media screen and(max-width: $x-small-screen) {
    padding-right: .25em;
    font-size: .8rem;
  }
}

.dc-home-first .skip-links {
  @media screen and(max-width: $large-screen) {
    > li {
      background: rgba(255,255,255,.6);
    }

    > li:first-child {
      margin-right: -.25rem;
    }
  }
}

.site-title {
  margin: 0;
  line-height: 1.25;
  font-size: 1.75rem;
  font-family: $serif;
  font-weight: normal;

  @media screen and(min-width: $x-small-screen) {
    font-size: 2rem;
  }

  @media screen and (min-width: $large-screen) {
    font-size: 3rem;
  }
}

.site-baseline {
  margin-top: 0;

  @media screen and (min-width: $large-screen) {
    font-size: 1.5rem;
  }
}
